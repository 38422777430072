/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from '../../actions';
import createFilterGroups from './utils/createFilterGroups';
import { recursiveFilterIds } from './utils/recursiveFilterIds';
import { DefaultButton } from '@fluentui/react';

import Filters from '../gooey/Filters';

import * as TextMapping from '../../utils/textMapping';
import { isEmpty } from 'lodash';
import { isEqual } from 'lodash';
import { usePluginListener } from '../../utils/plugin';

const mapStateToProps = (state) => {
  return {
    applications: state.applications,
    jobs: state.jobs,
    roots: state.roots,
    filterData: state.roots.filterData,
    previousFilterData: state.roots.previousFilterData,
    favoriteView: state.roots.favoriteView,
    appContent: state.roots.appContent,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(actions, dispatch);
};

function FilterControlContainer(props) {
  const {
    qs,
    history,
    tags,
    counts,
    getApps,
    sortColumn,
    sortDirection,
    view,
    updateLoadIndex,
    updateOffset,
    cleanEntityItems,
    searchFilters,
    roots,
    texts,
    clearSearch,
    scrollableTarget,
    favoriteView,
    setFavoriteView,
    appContent,
  } = props;

  const [filterGroups, setFilterGroups] = useState([]);
  const [oldQs, setOldQs] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);

  const [urlParams, setUrlParams] = useState([]);

  useEffect(() => {
    const nextUrlParams = filterGroups.map((group) => group.urlParam || group.name);
    setUrlParams(nextUrlParams);
  }, [filterGroups]);

  useEffect(() => {
    const queryParams = new URLSearchParams(history.location.search);
    let selectedIds = [];
    Array.from(queryParams.entries()).forEach(([key, value]) => {
      if (urlParams.includes(key)) {
        selectedIds = [...selectedIds, ...value.split(',')];
        selectedIds = selectedIds.map((selectedId) => {
          return selectedId.split('-')[1];
        });
      }
    });

    if (roots.filterData && !isEmpty(roots.filterData.featfilter)) {
      selectedIds = selectedIds.concat(roots.filterData.featfilter.flat().map((value) => value.toString()));
    }

    setSelectedItems(selectedIds);
  }, [history, filterGroups, urlParams, roots.filterData]);

  useEffect(() => {
    if (
      (!isEqual(qs.current, oldQs) || roots.filterDataStatus === 'STALE' || roots.status === null) &&
      roots.status !== 'FETCHING' &&
      roots.status !== 'CLEANING'
    ) {
      if (roots.hasSearchCriteria && oldQs !== null) {
        let reload = false;

        if (!isEqual(qs.current, oldQs) || roots.status === null) {
          reload = true;
        }

        getApps(qs.current, sortColumn, sortDirection, view, reload);
      }

      setOldQs(JSON.parse(JSON.stringify(qs.current)));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roots.filterDataStatus, scrollableTarget, roots.status, qs.current, roots.hasSearchCriteria]);

  useEffect(() => {
    if (!tags) return;

    const urlParams = new URLSearchParams(history.location.search);

    const newFilterGroups = createFilterGroups({
      tags,
      counts,
      urlParams,
      selectedItems,
    });

    setFilterGroups(newFilterGroups);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.search, counts, getApps, tags, roots.filterData]);

  const handleToggleGroup = useCallback((groups) => {
    setFilterGroups(groups);
  }, []);

  function resetFilters() {
    const currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.delete('tags');
    currentUrlParams.delete('pattern');
    currentUrlParams.delete('favorites');

    setFavoriteView(false);

    // remove product links
    let regex = /datasheet\/[^/]+\/[^/]+\/[^/]+/;

    let newURL = window.location.pathname.replace(regex, '');

    history.push({
      pathname: !isEmpty(newURL) ? newURL : '/',
      search: currentUrlParams.toString(),
    });

    clearSearch();
  }

  function pluginFilter(value) {
    const newUrlParams = [];

    const currentUrlParams = new URLSearchParams(window.location.search);

    updateLoadIndex(true);
    updateOffset();
    cleanEntityItems();

    let newUrlParamTags;

    if (currentUrlParams.has('tags')) {
      const urlParamTags = currentUrlParams.get('tags').split(',');

      if (!urlParamTags.includes(value)) {
        newUrlParamTags = [...urlParamTags, value];
      } else {
        newUrlParamTags = urlParamTags.filter((tag) => tag !== value);
      }

      newUrlParams.push(`tags=${newUrlParamTags}`);
    } else {
      newUrlParamTags = [value];
      newUrlParams.push(`tags=${newUrlParamTags}`);
    }

    history.push({
      pathname: history.location.pathname,
      search: newUrlParams.toString(),
    });

    getApps(new URLSearchParams(newUrlParams.toString()), sortColumn, sortDirection, view);
  }

  usePluginListener(appContent, [
    { type: 'resetfilters', callback: resetFilters },
    { type: 'filter', callback: pluginFilter },
  ]);

  const handleResetFilter = useCallback(
    ({ group }) => {
      const currentUrlParams = new URLSearchParams(history.location.search);
      const urlParam = group.urlParam || group.name;
      const filterIds = recursiveFilterIds(group.filters);

      updateLoadIndex(true);
      updateOffset();
      cleanEntityItems();

      let values = currentUrlParams.get(urlParam).split(',');
      values = values.filter((value) => !filterIds.includes(value));

      if (values.length > 0) {
        currentUrlParams.set(urlParam, `${values.join(',')}`);
      } else {
        currentUrlParams.delete(urlParam);
      }

      history.push({
        pathname: history.location.pathname,
        search: currentUrlParams.toString(),
      });
    },
    [history, updateLoadIndex, updateOffset, cleanEntityItems]
  );

  const handleChange = useCallback(
    ({ filter, group }) => {
      const { entityPath, isTag } = group;

      const newUrlParams = [];
      const urlParam = entityPath ? `o.${entityPath}` : group.urlParam;
      const currentUrlParams = new URLSearchParams(history.location.search);

      currentUrlParams.delete('favorites');

      if (scrollableTarget) {
        scrollableTarget.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      }

      updateLoadIndex(true);
      updateOffset();
      cleanEntityItems();
      function addValues(filter, values) {
        values.push(filter.groupVkey + '-' + filter.id);

        /*if (filter.children) {
          filter.children.forEach(child => {
            addValues(child, values);
          });
        }*/
      }

      function findAncestors(parentIds, filter, value, result) {
        if (filter.groupVkey + '-' + filter.id === value) {
          result.push(...parentIds);
        } else {
          if (filter.children) {
            filter.children.map((childFilter) => {
              return findAncestors([...parentIds, filter.groupVkey + '-' + filter.id], childFilter, value, result);
            });
          } else if (filter.filters) {
            filter.filters.map((childFilter) => {
              return findAncestors([], childFilter, value, result);
            });
          }
        }
      }

      function compareFilterValue(filter, value) {
        return filter.groupVkey + '-' + filter.id === value;
        /*   return true;
        } else {
          /*
          if (filter.children) {
            return filter.children.some(childFilter => {
              return compareFilterValue(childFilter, value);
            });
          } else {
            return false;
          
          return false;
        }*/
      }

      function findChildren(filter, result) {
        for (let child of filter.children) {
          let childKey = filter.groupVkey + '-' + child.id;

          result.push(childKey);

          if (child.children && !isEmpty(child.children)) {
            findChildren(child, result);
          }
        }
      }

      if (isTag) {
        if (currentUrlParams.has('tags')) {
          const urlParamTags = currentUrlParams.get('tags').split(',');
          let newUrlParamTags;

          if (filter.value) {
            newUrlParamTags = [...urlParamTags, filter.id];
          } else {
            newUrlParamTags = urlParamTags.filter((tag) => tag !== filter.id);
          }

          newUrlParams.push(`tags=${newUrlParamTags}`);
        }
      } else {
        if (group.type === 'boolean') {
          let values = [];
          if (group.multiple) {
            if (currentUrlParams.get(urlParam)) {
              values = currentUrlParams.get(urlParam).split(',');
            }
            if (filter.value === true) {
              addValues(filter, values);
            } else {
              values = values.filter((value) => {
                return !compareFilterValue(filter, value);
              });

              let result = [];
              // remove ancestors that may be checked
              findAncestors([], group, filter.groupVkey + '-' + filter.id, result);
              values = values.filter((value) => {
                return !result.includes(value);
              });
            }
          } else if (filter.value === true) {
            addValues(filter, values);
          }

          let result = [];
          // remove ancestors that may be checked
          findAncestors([], group, filter.groupVkey + '-' + filter.id, result);
          values = values.filter((value) => {
            return !result.includes(value);
          });

          /*if(values.includes(parentKey)) {
            values = values.filter(key => { return key !== parentKey});
          }*/

          let childrenKeys = [];

          findChildren(filter, childrenKeys);

          for (let childKey of childrenKeys) {
            if (values.includes(childKey)) {
              values = values.filter((key) => {
                return key !== childKey;
              });
            }
          }

          if (values.length > 0) {
            newUrlParams.push(`${urlParam}=${values.join(',')}`);
          }
        }

        if (group.type === 'enum' && group.value !== 'All') {
          newUrlParams.push(`${urlParam}=${group.value}`);
        }
      }

      [...currentUrlParams.entries()].forEach(([key, value]) => {
        const isMatch = key === `${urlParam}`;

        if (!isMatch && key !== 'tags') {
          newUrlParams.push(`${key}=${encodeURIComponent(value)}`);
        }
      });

      const { pathname } = history.location;
      const newUrl = `${pathname}?${newUrlParams.join('&')}`;

      history.push(newUrl);
    },
    [history, cleanEntityItems, updateLoadIndex, updateOffset, scrollableTarget]
  );

  let header = useMemo(() => {
    return { name: TextMapping.getUIText(TextMapping.UI_TEXT_FILTERS, texts) };
  }, [texts]);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div>
        {(selectedItems.length > 0 ||
          !isEmpty(qs.current.tags) ||
          !isEmpty(qs.current.pattern) ||
          !isEmpty(qs.current.favorites) ||
          favoriteView ||
          (!isEmpty(roots.filterData) && !isEmpty(roots.filterData.similar)) ||
          (!isEmpty(roots.filterData) &&
            roots.filterData.featfilter &&
            roots.filterData.diafilter &&
            roots.filterData.propfilter &&
            (roots.filterData.featfilter.length > 0 ||
              roots.filterData.diafilter.length > 0 ||
              roots.filterData.propfilter.length > 0))) && (
          <div>
            <DefaultButton
              className="clearAllFiltersButton clearAllFiltersButtonBackground"
              onClick={resetFilters}
              styles={{ root: { width: '100%' } }}
            >
              {TextMapping.getUIText(TextMapping.UI_TEXT_CLEAR_ALL_FILTERS, texts)}
            </DefaultButton>
          </div>
        )}

        <Filters
          appContent={appContent}
          header={header}
          groups={filterGroups}
          onChange={handleChange}
          toggleGroup={handleToggleGroup}
          selectedItems={selectedItems}
          onResetFilter={handleResetFilter}
          searchFilters={searchFilters}
        />
      </div>
    </div>
  );
}

FilterControlContainer.propTypes = {
  getApps: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(FilterControlContainer));
